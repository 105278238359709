import {mimes} from './mime';
import ysFixWebmDuration from './vendor/fix-duration-meta-data';

let constraintObj = {
    audio: false,
    video: {
        facingMode: 'user',
        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
    },
};
if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
    navigator.mediaDevices.getUserMedia = function (constraintObj) {
        let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }
        return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraintObj, resolve, reject);
        });
    };
} else {
    navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
            devices.forEach((device) => {
                console.log(device.kind.toUpperCase(), device.label);
            });
        })
        .catch((err) => {
            console.log(err.name, err.message);
        });
}

function getDateTime() {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return date + '_' + time;
}

let sec = 0;
let min = 0;
let t;
let startTime;

function tick() {
    sec++;
    if (sec >= 60) {
        sec = 0;
        min++;
        if (min >= 60) {
            min = 0;
        }
    }
}
function add() {
    tick();
    document.getElementById('time-elapsed').innerHTML = (min > 9 ? min : '0' + min) + ':' + (sec > 9 ? sec : '0' + sec);
    timer();
}
function timer() {
    t = setTimeout(add, 1000);
}

function resetTimer() {
    sec = 0;
    min = 0;
    document.getElementById('time-elapsed').innerHTML = (min > 9 ? min : '0' + min) + ':' + (sec > 9 ? sec : '0' + sec);
}

navigator.mediaDevices
    .getUserMedia(constraintObj)
    .then(function (mediaStreamObj) {
        const startRecording = function () {
            timer();
            startTime = Date.now();
            document.getElementsByClassName('video-recorder')[0].classList.add('active');
            isRecording = true;
            mediaRecorder.start();
        };
        const stopRecording = function () {
            clearTimeout(t);
            isRecording = false;
            mediaRecorder.stop();
            document.getElementById('videoElement').classList.add('hidden');
            document.getElementsByClassName('out-total-time')[0].innerHTML =
                (min > 9 ? min : '0' + min) + ':' + (sec > 9 ? sec : '0' + sec);
            document.getElementById('toolbar-video-capture').classList.add('hidden');
            document.getElementsByClassName('video-recorder')[0].classList.remove('active');
            document.getElementById('vid2').classList.remove('hidden');
            document.getElementsByClassName('video-recorder')[0].classList.add('preview');
        };
        let video = document.querySelector('video');
        if ('srcObject' in video) {
            video.srcObject = mediaStreamObj;
        } else {
            video.src = window.URL.createObjectURL(mediaStreamObj);
        }
        video.onloadedmetadata = function (ev) {
            video.play();
        };

        let recButton = document.getElementById('recButton');
        let vidSave = document.getElementById('vid2');
        let mediaRecorder = new MediaRecorder(mediaStreamObj);
        let chunks = [];
        let isRecording = false;
        let startTime;
        recButton.addEventListener('click', (ev) => {
            !isRecording ? startRecording() : stopRecording();
        });
        mediaRecorder.ondataavailable = function (ev) {
            chunks.push(ev.data);
        };
        mediaRecorder.onstop = (ev) => {
            const duration = Date.now() - startTime;
            let blob = new Blob(chunks, { type: chunks[0].type });
            const extension = mimes[chunks[0].type.split(';')[0]]?.extensions || 'webm';
            chunks = [];
            vidSave.src = window.URL.createObjectURL(blob);
            (ysFixWebmDuration || window.ysFixWebmDuration)(blob, duration).then(function(fixedBlob) {
              const movieName = 'Movie-' + getDateTime() + '.' + extension;
              document.getElementsByClassName('btn-save')[0].href = window.URL.createObjectURL(fixedBlob);
              document.getElementsByClassName('btn-save')[0].download = movieName;
              document.getElementsByClassName('btn-save')[0].title = movieName;
            });
        };
    })
    .catch(function (err) {
        console.log(err.name, err.message);
    });

function toggleFullScreen(id) {
    if (!document.fullscreenElement) {
        var elem = document.getElementById(id);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}

const photo = document.getElementsByClassName('img')[0];

function clearphoto() {
    const context = canvas.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);

    const data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
    document.getElementsByClassName('video-recorder')[0].classList.remove('preview-photo');
}
function clearvideo() {
    resetTimer();
    isVideoPlaying = false;
    document.getElementsByClassName('video-player-wrapper')[0].classList.remove("play-mode");
    document.getElementsByClassName('video-recorder')[0].classList.remove('preview');
    document.getElementById('vid2').classList.add('hidden');
    document.getElementsByClassName('video-recorder')[0].classList.remove('active');
    document.getElementById('toolbar-video-capture').classList.remove('hidden');
    document.getElementById('videoElement').classList.remove('hidden');
    document.getElementsByClassName('btn-save')[0].href = '';
    document.getElementsByClassName('btn-save')[0].download = '';
    document.getElementsByClassName('btn-save')[0].title = '';
}

const fullscreenButtons = document.getElementsByClassName('btn-fs');

for (var i = 0; i < fullscreenButtons.length; i++) {
    fullscreenButtons[i].addEventListener('click', function () {
        toggleFullScreen(
            document.getElementById('videoElement').classList.contains('hidden') ? 'vid2' : 'videoElement'
        );
    });
}

document.getElementsByClassName('btn-photo')[0].addEventListener('click', function () {
    const video = document.querySelector('#videoElement');
    document.getElementsByClassName('video-recorder')[0].classList.add('preview-photo');
    canvas.width = 3840;
    canvas.height = 2160;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const image_data_url = canvas.toDataURL('image/jpeg', 1.0).replace('image/jpeg', 'image/octet-stream');
    photo.setAttribute('src', image_data_url);

    document.getElementsByClassName('btn-save')[1].href = image_data_url;
    document.getElementsByClassName('btn-save')[1].download = 'Picture-' + getDateTime() + '.jpg';
});

document.getElementsByClassName('btn-del')[1].addEventListener('click', function () {
    clearphoto();
});
document.getElementsByClassName('btn-del')[0].addEventListener('click', function () {
    clearvideo();
});
document.getElementsByClassName('btn-close')[0].addEventListener('click', function () {
    clearphoto();
});

let isVideoPlaying = false;

const calculateVideoTime = sec => {
  sec = sec % 3600;
  let min = Math.floor(sec / 60);
  sec = Math.floor(sec % 60);
  if (sec.toString().length < 2) sec = '0' + sec;
  if (min.toString().length < 2) min = '0' + min;
  return min + ':' + sec;
};

document.getElementsByClassName('btn-play-video')[0].addEventListener('click', function () {
    const video = document.getElementById('vid2');
    video.ontimeupdate = function() {
      const totalWidth = document.getElementsByClassName('control-seek-bar')[0].clientWidth;
      const width = ((totalWidth / video.duration ) * video.currentTime);
      document.getElementsByClassName('control-seek-bar-stripe')[0].style.width = width + 'px';
      document.getElementsByClassName('control-seek-bar-dot')[0].style.left = width + 'px';
      const percentage = ( width / totalWidth );
      const vidTime = video.duration * percentage;
      document.getElementsByClassName('out-current-time')[0].innerHTML = calculateVideoTime(vidTime);
    };
    document.getElementsByClassName('video-player-wrapper')[0].classList.toggle("play-mode");
    isVideoPlaying = !isVideoPlaying;
    isVideoPlaying ? video.play() : video.pause();

});

document.getElementById('recButton').addEventListener('click', function () {
    if (document.getElementById('recButton').classList.contains('Rec')) {
        document.getElementById('recButton').classList.add('notRec');
        document.getElementById('recButton').classList.remove('Rec');
    } else {
        document.getElementById('recButton').classList.add('Rec');
        document.getElementById('recButton').classList.remove('notRec');
    }
});
